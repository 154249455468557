import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  FormGroup,
  Input,
  Row,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Container,
} from "reactstrap";
import { Image, Btn } from "../../AbstractElements";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import "./dashboardpos.css";
import axios from "../../axios/axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FiTrash } from "react-icons/fi";
import { FaPrint, FaSave, FaWhatsapp } from "react-icons/fa";
import { MdContactPhone, MdCreateNewFolder, MdDrafts } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin7Fill } from "react-icons/ri";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboardpos.css";
import {
  incrementDraftCount,
  decrementDraftCount,
} from "../../utils/draftSlice";
import { toggleModal } from "../../utils/modalSlice";
import moment from "moment";
import { IoIosAddCircle } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Dashboardpos = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const { isOpen } = useSelector((store) => store.modaldraft);
  console.log(isOpen, "isOpen");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchBarcode, setSearchBarcode] = useState("");
  const [firstAPICallMade, setFirstAPICallMade] = useState(false);

  // navbar
  const [showMore, setShowMore] = useState(false);

  const handleMoreClick = () => {
    setShowMore(!showMore);
    setSelectedMaincategory("More");
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();

  // attribute
  const [showattrmodal, setShowattrmodal] = useState(false);
  const [productImage, setProductImage] = useState(""); // State to store the product image URL
  const [product_selectid, setProductselectid] = useState("");
  const [selected_prod_index, setSelectedprodindex] = useState(null);
  const [attr_array, setAttrarray] = useState([]);
  const [json, setJson] = useState({});
  const [json_name, setJsonName] = useState({});

  // new pricelistwise
  const [attr_label, setAttrlabel] = useState([]);
  const [pricelist_array, setPricelistaarray] = useState([]);

  console.log(attr_label, "attr_label...");
  console.log(pricelist_array, "pricelist_array...");

  // const [selectedjson,setSelectedjson] = useState([])
  const [AttrProductname, setAttrProductname] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  console.log(attr_array, "arrayproductattribute.....");
  console.log(json, "json......");
  console.log(json_name, "json_name......");
  console.log(product_selectid, "product_selectid......");
  console.log(selectedProduct, "selectedProduct...");

  const image_url_logo = localStorage.getItem("company_logo_pos");

  const generatePDF = async () => {
    window.html2canvas = html2canvas;
    var rep = new jsPDF("p", "px", "a4");

    rep.html(document.querySelector("#rep1"), {
      callback: async function (pdf) {
        // pdf.save("Invoice.pdf");

        const blobPDF = new Blob([rep.output("blob")], {
          type: "application/pdf",
        });
        console.log(blobPDF, "blobPDF...");
        const customerName = contact_name || CashContact?.name;
        const message = `Hi ${customerName}, Attached is your invoice. Let me know if you need anything else.`;

        // Create a FormData object and append the PDF blob
        const formData = new FormData();
        formData.append("image", blobPDF);
        formData.append("invoice_id", Invoice_no || 10);
        formData.append("contact_id", contact_id || CashContact?.id);
        formData.append("countrycode", 91);
        formData.append(
          "mobileno",
          selectedContact?.mobile || CashContact?.mobile
          // 7048809244
        );
        formData.append("wp_clientmsg", message);
        formData.append("contactType", 1);
        formData.append("AUTHORIZEKEY", AUTHORIZE_KEY);
        formData.append("PHPTOKEN", localStorage.getItem("id_token"));
        setWhatsappbtn(false);

        try {
          // Make a POST request to your API endpoint
          const response = await fetch(
            `${baseurl}/expo_access_api/invoicePdfsendfdtowhatsapp/`,
            {
              method: "POST",
              body: formData,
            }
          );

          if (response.ok) {
            console.log("PDF successfully sent to the server");
            // Handle success
            setShowgeneratetext(false);
            setWhatsappbtn(false);
            toast.success("Send message successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            console.error("Failed to send PDF to the server");
            // Handle error
            setWhatsappbtn(false);
          }
        } catch (error) {
          console.error("Error while sending PDF to the server:", error);
          // Handle error
          setWhatsappbtn(false);
        } finally {
        }
      },
    });
  };

  useEffect(() => {
    // Debounce the API call with a delay of 1000 milliseconds (adjust as needed)
    const delay = 1000;
    let timeoutId;

    const delayedAPICall = async () => {
      try {
        setFirstAPICallMade(true);
        // Make the API call
        await getProductByBarcode(searchBarcode);
      } catch (error) {
        console.error("Error in getProductByBarcode API:", error);
      }
    };

    // Clear the timeout and reset it whenever the user types within the delay time
    const handleChange = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(delayedAPICall, delay);
    };

    // Call the delayedAPICall function when searchBarcode changes
    if (searchBarcode) {
      handleChange();
    }

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [searchBarcode]);

  // new json format
  const Saveattributejson = (index) => {
    if (!product_selectid || index < 0 || index >= pricelist_array.length)
      return;

    const selectedEntry = pricelist_array[index];

    if (selectedEntry) {
      const selectedprice = selectedEntry.price; // Extracting price from the selected entry

      const newSelectedJson = {
        [product_selectid]: combinedArrayToJSON(
          attr_label,
          selectedEntry.attr_arr
        ),
      };
      const newSelectedJsonName = {
        [product_selectid]: combinedArrayToJSONName(
          attr_label,
          selectedEntry.attr_arr
        ),
      };

      setJson(newSelectedJson);
      setJsonName(newSelectedJsonName);
      setCartinfo("Data found");
      getBranchWiseProductCurrentStock(
        selectedProduct,
        newSelectedJson,
        newSelectedJsonName,
        selectedprice
      );
    } else {
      // Handle the case when the product ID is not found
      console.error(`Product with ID ${product_selectid} not found.`);
    }
  };

  // Combine the two arrays based on index
  const combinedArrayToJSON = (attr_label, attr_arr) => {
    return Object.values(attr_label).reduce((result, attr, index) => {
      const value_id = String(attr.ID);
      const value = attr_arr[index]?.valueID;
      result[value_id] = value;
      return result;
    }, {});
  };

  const combinedArrayToJSONName = (attr_label, attr_arr) => {
    return Object.values(attr_label).reduce((result, attr, index) => {
      const label = attr.Label;
      const value = attr_arr[index]?.Value;
      result[label] = value;
      return result;
    }, {});
  };

  async function getProductPriceList(item, id, Productname, index) {
    try {
      if (id !== "") {
        const res = await axios.post(
          baseurl + "/expo_access_api/getProductPriceList/",
          {
            AUTHORIZEKEY: AUTHORIZE_KEY,
            product_id: id,
          }
        );

        console.log(res, "attribute");

        if (res && res.data && res.data[0].STATUS == "SUCCESS") {
          const pricelistArray = res.data[0].DATA.pricelist_array;

          if (Array.isArray(pricelistArray) && pricelistArray.length != 0) {
            console.log("Inside if loop getProductPriceList");
            setAttrlabel(res.data[0].DATA.attrlabel_arr);
            setPricelistaarray(pricelistArray);
            setProductselectid(id);
            setAttrProductname(Productname);
            setSelectedprodindex(index);
            setShowattrmodal(!showattrmodal);
            setSelectedProduct(item);
            setProductImage(item.original_photo_path); // Set the product image in the state
          } else {
            console.log("Inside else loop getProductPriceList");
            setCartloading(true);
            setCartinfo("Data found");
            // Ensure that this block is executed when pricelist_array is empty
            setAttrlabel([]);
            setPricelistaarray([]);
            setProductselectid(id);
            setSelectedProduct(item);
            getBranchWiseProductCurrentStock(item, "", "", "");
          }
        } else {
          setCartloading(true);
          setCartinfo("Data found");
          setAttrlabel([]);
          setPricelistaarray([]);
          setProductselectid(id);
          setSelectedProduct(item);
          getBranchWiseProductCurrentStock(item, "", "", "");
        }
      }
    } catch (error) {
      console.error("Error while fetching product attributes:", error);
      // Handle the error as needed, e.g., show an error message to the user.
    }
  }

  const Openatrributemodal = (item, id, Productname, index) => {
    getProductPriceList(item, id, Productname, index);
  };

  const toggleattributemodal = () => {
    setShowattrmodal(!showattrmodal);
    setProductImage(""); // Reset product image when closing the modal
  };

  const getBranchWiseProductCurrentStock = async (
    selectedProduct,
    selectedJson,
    selectedJson_Name,
    selectedprice
  ) => {
    console.log(selectedProduct, "inside getBranchWiseProductCurrentStock");
    console.log(selectedJson, "inside getBranchWiseProductCurrentStock");
    console.log(
      selectedJson_Name,
      "inside selectedJson_Name getBranchWiseProductCurrentStock"
    );
    console.log(
      product_selectid,
      "product_selectid inside getBranchWiseProductCurrentStock.."
    );
    try {
      const response = await axios.post(
        "/expo_access_api/getBranchWiseProductCurrentStock/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          product_id: selectedProduct?.product_id,
          Attributes: selectedJson,
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        // alert(response?.data[0]?.MSG);
        const stock = response.data[0]?.DATA;
        if (stock == 0) {
          toast.error("Stock is not available!.", {
            position: "top-right",
            autoClose: 5000, // 5 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setProductselectid("");
          setCartloading(false);
          setCartinfo("Data not found");
        } else {
          setCartloading(false);
          setCartinfo("Data found");
          handleAddToCart(
            selectedProduct,
            selectedUnits[selectedProduct.product_id] || 1,
            stock,
            selectedJson,
            selectedJson_Name,
            selectedprice
          );
        }

        selectedJson !== "" && setShowattrmodal(!showattrmodal);
      } else {
        setCartloading(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getProductCategoryList API:", error);
    }
  };

  /* prev contact logic */
  const [contactmodal, setContactmodal] = useState(false);
  const togglecontact = () => {
    setContactmodal(!contactmodal);
    const fieldsToReset = [
      "name",
      "mobile",
      "email",
      "address_type",
      "address1",
      "address2",
      "area",
      "city",
      "zipcode",
    ];
    fieldsToReset.forEach((field) => setValue(field, ""));
    // setValue("country", "India");
    // setValue("state","GUJARAT")
  };

  // added by umang
  // for add billing address
  const [nickname_billing, setNickNamebilling] = useState();
  const [address1_billing, setAddress1billing] = useState();
  const [address2_billing, setAddress2billing] = useState();
  const [area_billing, setAreabilling] = useState();
  const [city_billing, setCitybilling] = useState();
  const [selcountry_billing, setSelCountrybilling] = useState();
  const [selstate_billing, setSelStatebilling] = useState();
  const [zipcode_billing, setZipcodebilling] = useState();
  const [address_id, setAddressID] = useState("");
  const [billingmodal, setBillingmodal] = useState(false);
  const [billingadd, setBillingadd] = useState(false);

  // for select - option
  const [Address, setAddress] = useState([]);
  const [Billingaddress_id, setBillingaddressId] = useState("");
  console.log(Billingaddress_id, "Billingadddresid....");

  const togglebilling = () => {
    setBillingmodal(!billingmodal);
    setNickNamebilling("");
    setAddress1billing("");
    setAddress2billing("");
    setAreabilling("");
    setCitybilling("");
    setSelCountrybilling("");
    setSelStatebilling("");
    setZipcodebilling("");
  };

  const handleChangeFieldbilling = (e) => {
    if (e.target.name == "txt_add_type_new_billing") {
      setNickNamebilling(e.target.value);
    } else if (e.target.name == "txt_add_new1_billing") {
      setAddress1billing(e.target.value);
    } else if (e.target.name == "txt_add_new2_billing") {
      setAddress2billing(e.target.value);
    } else if (e.target.name == "txt_city_new_billing") {
      setCitybilling(e.target.value);
    } else if (e.target.name == "txt_area_new_billing") {
      setAreabilling(e.target.value);
    } else if (e.target.name == "txt_zipcode_new_billing") {
      if (selcountry == "India") {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          if (e.target.value.length > 6) {
          } else {
            setZipcodebilling(e.target.value);
          }
        }
      } else {
        setZipcodebilling(e.target.value);
      }
    } else if (e.target.name == "sel_state_new_billing") {
      setSelStatebilling(e.target.value);
    }
  };

  const contact_address = (contact_id) => {
    async function submitContactAdd() {
      try {
        let url = baseurl + "/expo_access_api/getContactBillingAddressForBot/";
        const res = await axios.post(url, {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          contact_id: contact_id,
          billing_type: "Y",
          contact_type: 1,
        });

        if (res.data[0].STATUS === "SUCCESS") {
          setAddress(res.data[0].DATA);
          const obj = res.data[0].DATA.find(
            (value) => value.billing_flg === "Y"
          );

          if (!obj) {
            setBillingaddressId("");
            setShippingaddressId("");
            // Reset other state variables if needed
          } else {
            setBillingaddressId(obj.address_id);
            setShippingaddressId(obj.address_id);
            setShowBillingModal(true);
            // Set other state variables if needed
          }
        } else {
          console.error("Error fetching data:", res.data[0].MSG);
          // Handle error, set error state, show a user-friendly message, etc.
          setAddress([]);
          setBillingaddressId("");
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getContactBillingAddressForBot API:", error);
        // Set an error state or show a user-friendly message
        // setErrorState(true);
        setAddress([]);
        setBillingaddressId("");
      }
    }

    submitContactAdd();
  };

  const contact_address_shipping = (contact_id) => {
    async function submitContactAdd() {
      try {
        let url = baseurl + "/expo_access_api/getContactBillingAddressForBot/";
        const res = await axios.post(url, {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          contact_id: contact_id,
          billing_type: "N",
          contact_type: 1,
        });

        if (res.data[0].STATUS === "SUCCESS") {
          setAddress(res.data[0].DATA);
          const obj = res.data[0].DATA.find(
            (value) => value.billing_flg === "Y"
          );

          if (!obj) {
            // setBillingaddressId("");
            setShippingaddressId("");
            // Reset other state variables if needed
          } else {
            // setBillingaddressId(obj.address_id);
            setShippingaddressId(obj.address_id);
            setShowShippingModal(true);
            // Set other state variables if needed
          }
        } else {
          console.error("Error fetching data:", res.data[0].MSG);
          // Handle error, set error state, show a user-friendly message, etc.
          setAddress([]);
          setShippingaddressId("");
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getContactBillingAddressForBot API:", error);
        // Set an error state or show a user-friendly message
        // setErrorState(true);
        setAddress([]);
        setShippingaddressId("");
      }
    }

    submitContactAdd();
  };

  const contact_address_draft = (draft) => {
    console.log(draft, "draft inside contact_address_draft");
    const { ShippingaddressId, BillingaddressId, contactid, Ship_Bill_Same } =
      draft;
    async function submitContactAdd() {
      try {
        let url = baseurl + "/expo_access_api/getContactBillingAddressForBot/";
        const res = await axios.post(url, {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          contact_id: contactid,
          billing_type: "Y",
          contact_type: 1,
        });

        if (res.data[0].STATUS === "SUCCESS") {
          setAddress(res.data[0].DATA);
          setBillingaddressId(BillingaddressId);
          setShippingaddressId(ShippingaddressId);
          if (Ship_Bill_Same) {
            setSameAsBilling(true);
            setShowBillingModal(true);
          } else {
            setSameAsBilling(false);
            setShowBillingModal(true);
            setShowShippingModal(true);
          }
        } else {
          console.error("Error fetching data:", res.data[0].MSG);
          // Handle error, set error state, show a user-friendly message, etc.
          setAddress([]);
          setBillingaddressId("");
          setShippingaddressId("");
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getContactBillingAddressForBot API:", error);
        // Set an error state or show a user-friendly message
        setAddress([]);
        setBillingaddressId("");
        setShippingaddressId("");
      }
    }

    submitContactAdd();
  };

  const handleSubmitAddressBilling = (e) => {
    console.log("umang nikhare...........");
    e.preventDefault();

    axios
      .post(baseurl + "/expo_access_api/saveContactAddress/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        contact_id: contact_id,
        contact_type: 1,
        address_type: nickname_billing,
        address1: address1_billing,
        address2: address2_billing,
        area: area_billing,
        city: city_billing,
        selcountry: selcountry_billing,
        selstate: selstate_billing,
        zipcode: zipcode_billing,
        billing_flg: "Y",
        billing_type: 0,
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          Swal.fire({
            title: response.data[0].MSG,
            icon: "success",
          });
          contact_address(contact_id);
          // setAddressID("");
          setNickNamebilling("");
          setAddress1billing("");
          setAddress2billing("");
          setAreabilling("");
          setCitybilling("");
          setSelCountrybilling("India");
          setSelStatebilling("GUJARAT");
          setZipcodebilling("");
          togglebilling();
        } else {
          console.error(
            "Error in saveContactAddress API:",
            response.data[0].MSG
          );
          // Handle error, set error state, show a user-friendly message, etc.
        }
      })
      .catch((error) => {
        // Handle the error
        console.error("Error in saveContactAddress API:", error);
        // Set an error state or show a user-friendly message
        // setErrorState(true);
      });
  };

  // added by umang
  // for add shipping address
  const [nickname_shipping, setNickNameShipping] = useState();
  const [address1_shipping, setAddress1Shipping] = useState();
  const [address2_shipping, setAddress2Shipping] = useState();
  const [area_shipping, setAreaShipping] = useState();
  const [city_shipping, setCityShipping] = useState();
  const [selcountry_shipping, setSelCountryShipping] = useState();
  const [selstate_shipping, setSelStateShipping] = useState();
  const [zipcode_shipping, setZipcodeShipping] = useState();
  const [shippingmodal, setShippingmodal] = useState(false);
  const [shippigadd, setShippingadd] = useState(false);

  const [Shippingaddress_id, setShippingaddressId] = useState("");
  console.log(Shippingaddress_id, "Shippingaddress_id.....");

  const toggleshipping = () => {
    setShippingmodal(!shippingmodal);
    setNickNameShipping("");
    setAddress1Shipping("");
    setAddress2Shipping("");
    setAreaShipping("");
    setCityShipping("");
    setSelCountryShipping("");
    setSelStateShipping("");
    setZipcodeShipping("");
  };

  const handleChangeFieldShipping = (e) => {
    if (e.target.name === "txt_add_type_new_shipping") {
      setNickNameShipping(e.target.value);
    } else if (e.target.name === "txt_add_new1_shipping") {
      setAddress1Shipping(e.target.value);
    } else if (e.target.name === "txt_add_new2_shipping") {
      setAddress2Shipping(e.target.value);
    } else if (e.target.name === "txt_city_new_shipping") {
      setCityShipping(e.target.value);
    } else if (e.target.name === "txt_area_new_shipping") {
      setAreaShipping(e.target.value);
    } else if (e.target.name === "txt_zipcode_new_shipping") {
      if (selcountry_shipping === "India") {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          if (e.target.value.length > 6) {
            // Handle length limit if needed
          } else {
            setZipcodeShipping(e.target.value);
          }
        }
      } else {
        setZipcodeShipping(e.target.value);
      }
    } else if (e.target.name === "sel_state_new_shipping") {
      setSelStateShipping(e.target.value);
    }
  };

  const handleSubmitAddressShipping = (e) => {
    e.preventDefault();

    axios
      .post(baseurl + "/expo_access_api/saveContactAddress/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        contact_id: contact_id,
        contact_type: 1,
        address_type: nickname_shipping, // Assuming you have a nickname_shipping state
        address1: address1_shipping, // Assuming you have an address1_shipping state
        address2: address2_shipping, // Assuming you have an address2_shipping state
        area: area_shipping, // Assuming you have an area_shipping state
        city: city_shipping, // Assuming you have a city_shipping state
        selcountry: selcountry_shipping, // Assuming you have a selcountry_shipping state
        selstate: selstate_shipping, // Assuming you have a selstate_shipping state
        zipcode: zipcode_shipping, // Assuming you have a zipcode_shipping state
        billing_flg: "Y", // Set to "N" for shipping address
        billing_type: 1,
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          Swal.fire({
            title: response.data[0].MSG,
            icon: "success",
          });
          contact_address_shipping(contact_id);
          // setAddressID("");
          setNickNameShipping("");
          setAddress1Shipping("");
          setAddress2Shipping("");
          setAreaShipping("");
          setCityShipping("");
          setSelCountryShipping("India");
          setSelStateShipping("GUJARAT");
          setZipcodeShipping("");
          toggleshipping(); // Assuming you have a toggleShipping function
        } else {
          console.error(
            "Error in saveContactAddress API:",
            response.data[0].MSG
          );
          // Handle error, set error state, show a user-friendly message, etc.
        }
      })
      .catch((error) => {
        // Handle the error
        console.error("Error in saveContactAddress API:", error);
        // Set an error state or show a user-friendly message
        // setErrorState(true);
      });
  };

  const [contacts, setContacts] = useState([]);
  const [country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [selcountry, setSelCountry] = useState("");
  const [addcontact, setAddContact] = useState({
    name: "",
    mobile: "",
    email: "",
    address_type: "",
    address1: "",
    address2: "",
    country: "",
    area: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const [contactbtn, setContactbtn] = useState(false);
  const [contact_id, setContactid] = useState("");
  const [contact_name, setContactname] = useState("");
  const [contact_number, setContactnumber] = useState("");

  const animatedComponents = makeAnimated();

  // console.log(contacts + "contacts");

  useEffect(() => {
    submitfile();
  }, []);

  const [CashContact, setCashContact] = useState({});
  async function submitfile() {
    try {
      let url = /* baseurl + */ "/expo_access_api/getContactTextile/";
      const res = await axios.post(url, {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        created_by: 1,
      });

      if (res.data[0].STATUS === "SUCCESS") {
        console.log("done");
        console.log(res.data[0].DATA);
        const cashContact = res.data[0].DATA.find(
          (contact) => contact.name == "Cash Contact"
        );
        setContacts(res.data[0].DATA);
        setCashContact(cashContact);
      } else {
        console.log("undone");
      }
    } catch (error) {
      // Handle the error
      console.error("Error in API call:", error);

      // You can also set an error state or show a user-friendly message
      // setErrorState(true);
    }
  }

  const getCountryList = (e) => {
    async function findCountryList() {
      try {
        let url = /* baseurl + */ "/expo_access_api/getCountryList/";
        const res = await axios.post(url, {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        });

        if (res.data[0].STATUS === "SUCCESS") {
          setCountry(res.data[0].DATA);
          setValue("country", "India");
          changestate(e, "India");
        } else {
          console.log("undone");
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getCountryList API:", error);

        // You can also set an error state or show a user-friendly message
        // setErrorState(true);
      }
    }

    findCountryList();
  };

  const changestate = (e, val) => {
    var element_val = val === 0 ? e.target.value : val;
    setSelCountry(element_val);
    setSelCountrybilling(element_val);
    setSelCountryShipping(element_val);
    setAddContact({ ...addcontact, country: element_val });

    async function getStates() {
      try {
        const res = await axios.post(
          /* baseurl + */ "/expo_access_api/getstatelist/",
          {
            AUTHORIZEKEY: AUTHORIZE_KEY,
            COUNTRY: element_val,
          }
        );

        if (res.data[0].STATUS === "SUCCESS") {
          if (res.data[0].DATA.state) {
            setState(res.data[0].DATA.state);
            setValue("state", "GUJARAT");
          }
        } else {
          Swal.fire("Not Found", "State Not Found", "error");
          setState([]);
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getStates API:", error);

        // You can also set an error state or show a user-friendly message
        // setErrorState(true);
      }
    }

    getStates();
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const handleSubmitContact = async (data, e) => {
    setContactbtn(true);
    e.preventDefault();

    async function submitContact() {
      try {
        let url = /*baseurl + */ "/ecommerce_api/saveContactPOS/";
        const res = await axios.post(url, {
          contact: data,
        });

        if (res.data[0].STATUS === "SUCCESS" && res.data[0].DATA > 0) {
          submitfile();
          const contactId = res.data[0].DATA;
          const fieldsToReset = [
            "name",
            "mobile",
            "email",
            "address_type",
            "address1",
            "address2",
            "area",
            "city",
            "zipcode",
          ];
          fieldsToReset.forEach((field) => setValue(field, ""));
          setContactid(res.data[0].DATA);
          // localStorage.setItem("contactid", contactId);
          console.log(res.data[0].DATA + "contactid");
          setContactbtn(false);
          setContactmodal(!togglecontact);
          setBillingadd(true);
          Swal.fire("Good job!", "Contact " + res.data[0].MSG, "success");
          contact_address(res.data[0].DATA);
        } else {
          Swal.fire("Something went wrong", res.data[0].MSG, "error");
          setContactbtn(false);
        }
      } catch (error) {
        // Handle the error
        console.error("Error in submitContact API:", error);

        // You can also set an error state or show a user-friendly message
        // setErrorState(true);
        setContactbtn(false);
      }
    }

    submitContact();
  };

  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    // Update selectedContact when contact_id changes
    const updatedSelectedContact = contacts.find(
      (contact) => contact.id === contact_id
    );
    console.log(updatedSelectedContact, "updatedSelectedContact");

    // Set the selectedContact state
    setSelectedContact(updatedSelectedContact);
  }, [contact_id, contacts]);

  console.log(selectedContact, "selectedconatct");

  /**************** CONTACT , BILLING ADDR, SHIPPING ADDR ****************/

  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [sameAsBilling, setSameAsBilling] = useState(true);

  const [addCustomerDetails, setAddCustomerDetails] = useState(false);
  const [Savebtn, setSavebtn] = useState(false);

  console.log(contact_id, "contactid.....new");
  console.log(Billingaddress_id, "Billingaddress_id...");
  console.log(Shippingaddress_id, "Billingaddress_id");

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption, "selectedOption");
    setContactid(selectedOption.value);
    setContactname(selectedOption.contactname);
    setContactnumber(selectedOption.mobile);
    // fetchAddressesForContact(selectedOption.value);
    contact_address(selectedOption.value);
    setShowBillingModal(true);
  };

  const handleCheckboxChange = () => {
    setShowShippingModal(sameAsBilling); // Show shipping modal if unchecked
    setSameAsBilling(!sameAsBilling); // Toggle sameAsBilling
  };

  /***************** MANAGING CATEGORY AND PRODUCT DISPLAYS *****************/

  const [categoryData, setCategoryData] = useState([]); //stores all api fetched categories (does not contain "All")
  const [MaincategoryData, setMaincategoryData] = useState([]); //stores all api fetched categories (does not contain "All")
  const [allProducts, setAllProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); //stores category object selected, value/name are properties
  const [displayFilteredItems, setDisplayFilteredItems] = useState([]); //sets product display

  const [categoryProducts, setCategoryProducts] = useState([]); //stores category wise api fetched products
  const [isSelected, setIsSelected] = useState("");
  console.log(isSelected, "isSelected");
  const [selectedMaincategory, setSelectedMaincategory] = useState("ALL");

  const [loading, setLoading] = useState(true);
  const [category_info, setCategoryinfo] = useState("");
  const [product_info, setProductinfo] = useState("");

  const [categorySearchQuery, setCategorySearchQuery] = useState("");

  console.log(categoryData, "categoryData....");
  console.log(MaincategoryData, "MaincategoryData....");
  console.log(selectedCategory, "selectedCategory...");
  console.log(displayFilteredItems, "displayFilteredItems..");

  // Maincategories
  const fetchMaincategories = async () => {
    try {
      const response = await axios.post(
        "/ecommerce_api/getProductCategoryList/",
        {
          tree: true,
          AUTHORIZEKEY: AUTHORIZE_KEY,
          PHPTOKEN: localStorage.getItem("id_token"),
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        console.log("product category list:", response.data[0].DATA);
        setMaincategoryData(response.data[0].DATA.categorylist);
      } else {
        setMaincategoryData([]);
        setCategoryinfo("Data Not Found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getProductCategoryList API:", error);

      // You can also set an error state or show a user-friendly message
      // setErrorState(true);
    }
  };

  useEffect(() => {
    fetchMaincategories();
  }, []);

  // Subcategories
  const fetchSubcategories = async () => {
    try {
      const response = await axios.post(
        "/ecommerce_api/getProductCategoryList/",
        {
          tree: false,
          AUTHORIZEKEY: AUTHORIZE_KEY,
          PHPTOKEN: localStorage.getItem("id_token"),
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        console.log("product category list:", response.data[0].DATA);
        setCategoryData(response.data[0].DATA.categorylist);
      } else {
        setCategoryData([]);
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getProductCategoryList API:", error);
    }
  };

  useEffect(() => {
    fetchSubcategories();
  }, []);

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data

    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/ecommerce_api/getCategorywiseProductList/",
          {
            category_name: "ALL",
          }
        );

        if (response.data[0].STATUS === "SUCCESS") {
          const data = response.data[0]?.DATA;
          const filteredItems = data ? data.products : [];

          if (filteredItems && filteredItems.length > 0) {
            setAllProducts(filteredItems);
            setDisplayFilteredItems(filteredItems);
            console.log("ALL products:", filteredItems);
          } else {
            setDisplayFilteredItems([]);
          }
        } else {
          console.error("Error fetching data:", response.data[0].MSG);
          // Handle error, set error state, show a user-friendly message, etc.
        }
      } catch (error) {
        // Handle the error
        console.error("Error in getCategorywiseProductList API:", error);
        // Set an error state or show a user-friendly message
        // setErrorState(true);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchData();
  }, []);
  console.log(displayFilteredItems, "displayFilteredItems..products");

  // fetchCategorywiseProductList
  const fetchCategorywiseProductList = async () => {
    setProductinfo("Data Found");
    setDisplayFilteredItems([]);
    try {
      const response = await axios.post(
        "/ecommerce_api/getCategorywiseProductList/",
        {
          category_name: selectedCategory,
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        // setProductinfo("")
        const data = response.data[0]?.DATA;
        const fetchedItems = data ? data.products : [];
        setCategoryProducts(fetchedItems);
        setDisplayFilteredItems(fetchedItems);
      } else {
        console.error("Error fetching data:", response.data[0].MSG);
        // Handle error, set error state, show a user-friendly message, etc.
        setCategoryProducts([]);
        setDisplayFilteredItems([]);
        setProductinfo("Data Not Found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getCategorywiseProductList API:", error);
      // Set an error state or show a user-friendly message
      setCategoryProducts([]);
      setDisplayFilteredItems([]);
      setProductinfo("Data Not Found");
    } finally {
      setLoading(false);
      setProductinfo("Data Not Found");
    }
  };

  const getProductByBarcode = async (bar_code) => {
    try {
      const response = await axios.post(
        "/expo_access_api/ProductdetailByBarcode/",
        {
          barcode: bar_code,
          AUTHORIZEKEY: AUTHORIZE_KEY,
        }
      );

      if (response?.data[0]?.STATUS === "SUCCESS") {
        const data = response?.data[0]?.DATA;
        const fetchedItems = data ? data.Product_Details : [];
        const item = fetchedItems[0];

        // Move getProductPriceList logic here
        if (item) {
          getProductPriceList(item, item.product_id, item.name, "");
        }
      } else {
        const errorMessage = response?.data[0]?.DATA?.MSG || "Data not found";

        // Use toast.error for consistent error messaging
        toast.error("Product is not available", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error in getProductByBarcode API:", error);

      // Set an error state or show a user-friendly message
      // setCartinfo("Data not found");

      // Use toast.error for consistent error messaging
      toast.error("Error fetching data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      // setCartloading(false);
    }
  };

  useEffect(() => {
    if (!selectedCategory) {
      // If selectedCategory is blank, do nothing
      return;
    }

    setLoading(true);
    fetchCategorywiseProductList();
  }, [selectedCategory]);

  const handleCategoryClick = (clickedCategory) => {
    setSelectedCategory(clickedCategory);
    setProductinfo("Data Found");
    console.log(clickedCategory.name + " : i got clicked!");
  };
  console.log(selectedCategory, "selectedCategory....");

  const handleCategorySearchChange = (event) => {
    setCategorySearchQuery(event.target.value.toLowerCase());
  };

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);

  useEffect(() => {
    const fetchFilteredCategories = async () => {
      try {
        // setLoading(true); // Set loading to true before fetching data

        // Simulate an asynchronous API call or data fetching
        // Replace the following line with your actual data fetching logic
        const filteredCategoriesResult = categoryData.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(categorySearchQuery.toLowerCase());
        });
        if (filteredCategoriesResult.length == 0 && categorySearchQuery != "") {
          setCategoryinfo("Data Not Found");
        } else {
          // setCategoryinfo("Data Found");
        }
        setFilteredCategories(filteredCategoriesResult);
      } finally {
        // setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchFilteredCategories();
  }, [categoryData, categorySearchQuery]);

  // Conditional rendering for categories
  useEffect(() => {
    // Update categoriesToDisplay when filteredCategories or categorySearchQuery changes
    setCategoriesToDisplay(
      categorySearchQuery ? filteredCategories : categoryData
    );
  }, [categorySearchQuery, filteredCategories, categoryData]);

  /************************ SEARCH *************************/
  console.log(searchQuery, "searchQuery for products");
  useEffect(() => {
    // Check if categoryProducts is empty
    if (categoryProducts.length == 0) {
      const filteredItems = allProducts.filter((item) => {
        const isNameMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isIdMatch = item.product_id.toString() === searchQuery.trim();
        return isNameMatch || isIdMatch;
      });
      if (filteredItems.length == 0 && searchQuery != "") {
        setProductinfo("Data Not Found");
      } else {
        console.log("product_info extra else.......");
        // setProductinfo("Data Found");
      }
      setDisplayFilteredItems(filteredItems);
    } else {
      // Apply search query filter on categoryProducts
      const filteredItems = categoryProducts.filter((item) => {
        const isNameMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isIdMatch = item.product_id.toString() === searchQuery.trim();
        return isNameMatch || isIdMatch;
      });
      if (filteredItems.length == 0 && searchQuery != "") {
        setProductinfo("Data Not Found");
      } else {
        console.log("product_info extra else.......");
        // setProductinfo("Data Found");
      }
      setDisplayFilteredItems(filteredItems);
    }
  }, [searchQuery, categoryProducts]);

  /**************** DRAFTS ****************/

  const dispatch = useDispatch();
  const localDrafts = useSelector((state) => state.drafts);

  const notifyDraft = () =>
    toast.success("Added to Drafts!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [drafts, setDrafts] = useState([]);
  console.log(drafts, "drafts....");

  // Function to add a draft to the drafts state
  const addDraft = (draft) => {
    dispatch(incrementDraftCount());
    setDrafts((prevDrafts) => [...prevDrafts, draft]);
  };

  const clearCart = () => {
    setCart([]);
  };

  const refillCartFromDraft = (draft) => {
    console.log(draft.items, "inside refillCartFromDraft...");
    const updatedSelectedUnits = draft.items.reduce((acc, item, index) => {
      acc[index] = item.selectedUnit;
      return acc;
    }, {});
    console.log(
      updatedSelectedUnits,
      "updatedSelectedUnits inside refillCartFromDraft.."
    );
    setSelectedUnits(updatedSelectedUnits);
    setCart(draft.items);
  };

  const deleteDraft = (draft) => {
    dispatch(decrementDraftCount());
    setDrafts((prevDrafts) =>
      prevDrafts.filter((anyDraft) => anyDraft !== draft)
    );
    console.log(drafts, "drafts....................");
  };

  const handleEdit = (draft, drafts) => {
    console.log(draft, "draft inside handleEdit");
    clearCart();
    refillCartFromDraft(draft);
    deleteDraft(draft);
    dispatch(toggleModal());
    if (draft.contactid != "") {
      setAddCustomerDetails(true);
      setContactid(draft.contactid);
      setContactname(draft.contactName);
      setContactnumber(draft.contactMobileNumber);
      contact_address_draft(draft);
    }
    console.log(drafts, "drafts....................");
  };

  const handleDelete = (draft, drafts) => {
    deleteDraft(draft);
    console.log(drafts, "drafts....................");
    dispatch(toggleModal());
  };

  /********************************* CART *********************************/

  const [cart, setCart] = useState([]);
  const [cart_loading, setCartloading] = useState(false);
  const [cart_info, setCartinfo] = useState("Data not found");
  console.log(cart, "cart check..");

  useEffect(() => {
    if (cart.length === 0) {
      setCartinfo("Data not found");
    } else {
      setCartinfo("Data found");
    }
  }, [cart]);

  // with showing attribute
  const handleAddToCart = (
    selectedProduct,
    selectedUnit,
    prod_stock,
    selectedJson,
    selectedJson_Name,
    selectedprice
  ) => {
    console.log("Prev Cart: ", cart);
    console.log(selectedProduct, "selectedProduct inside handleAddToCart");
    console.log(selectedJson, "selectedJson inside handleAddToCart");
    console.log(selectedJson_Name, "selectedJson_Name inside handleAddToCart");

    const updatedCart = [...cart];
    const existingProductIndex = updatedCart.findIndex(
      (item) =>
        item.product_id == selectedProduct.product_id &&
        item.stock == prod_stock
    );
    console.log(existingProductIndex, "existingProductIndex");

    if (existingProductIndex !== -1) {
      // Product with the same product_id and stock already exists in the cart
      const rowIndex = existingProductIndex;
      console.log(rowIndex, "rowIndex....");
      const item = updatedCart[rowIndex];
      console.log(item, "item inside handle addto cart");

      // Ensure the new quantity is a positive integer
      const newQuantity = item.quantity + 1;
      const validatedQuantity = Math.max(0, newQuantity);
      console.log(
        validatedQuantity,
        "validatedQuantity inside handle add to cart"
      );

      // Check if the new quantity is greater than the available stock
      console.log(parseFloat(item.stock, 10), "parseFloat(item.stock, 10)");
      if (validatedQuantity > parseFloat(item.stock, 10)) {
        setInvalidInput(rowIndex);
        // Display toast error
        toast.error("Quantity should not be greater than available stock.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return updatedCart.map((cartItem, idx) => {
          if (idx === rowIndex) {
            cartItem.quantity = item.quantity;
          }
          return cartItem;
        });
      } else {
        setInvalidInput(null);
      }
      // Update the quantity in the cart
      item.quantity = validatedQuantity;
    } else {
      // Product not in the cart, add a new entry
      updatedCart.push({
        product_id: selectedProduct.product_id,
        name: selectedProduct.name,
        mrp_price: selectedprice || selectedProduct.price1_min, // Use selectedprice if not blank, otherwise use the original value
        original_photo_path: selectedProduct.original_photo_path,
        quantity: 0,
        packing: selectedProduct.proddivision,
        unit_name: selectedProduct.unit_name,
        second_unit: selectedProduct.second_unit,
        conv_factor: selectedProduct.prod_conversion,
        taxPercentage: selectedProduct.Tax,
        selectedUnit: selectedUnit,
        stock: Number(prod_stock),
        product_attribute: selectedJson,
        product_attribute_Name: JSON.parse(JSON.stringify(selectedJson_Name)),
        productstockid: !!selectedProduct.ProductStockid
          ? selectedProduct.ProductStockid
          : "",
      });
    }

    console.log("Updated Cart:", updatedCart);
    setCart(updatedCart);
    setProductselectid("");
    setSelectedUnits((prevSelectedUnits) => ({
      ...prevSelectedUnits,
      [updatedCart.length - 1]: 1,
    }));
    console.log(selectedUnits, "selectedUnit...inside..handleAddToCart ");
  };

  const handleRemoveFromCart = (productId, index) => {
    console.log("LETS DELETE SOME DATA");

    const updatedCart = cart.filter((item, i) => i !== index);

    setCart(updatedCart);

    console.log("updated Cart after deletion: ", updatedCart);
  };

  const [invalidInput, setInvalidInput] = useState(null);
  console.log(invalidInput, "invalidInput...");

  const handleInputChange = (handler, rowIndex, value) => {
    // Updated regex to allow decimal values
    if (/^\d*\.?\d*$/.test(value)) {
      handler(rowIndex, value);
    }
  };

  const handleQuantityInputChange = (rowIndex, newQuantity) => {
    console.log(rowIndex, "rowIndex inside handleQuantityInputChange");
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      const item = updatedCart[rowIndex];

      // Ensure the new quantity is a positive integer
      const validatedQuantity = Math.max(0, parseFloat(newQuantity, 10) || 0);
      console.log(validatedQuantity, "validatedQuantity...");

      // Check if the new quantity is greater than the available stock
      if (validatedQuantity > parseFloat(item.stock, 10)) {
        // Display toast error
        setInvalidInput(rowIndex);

        toast.error("Quantity should not be greater than available stock.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Reset the input value to the previous valid quantity
        return updatedCart.map((cartItem, idx) => {
          if (idx === rowIndex) {
            cartItem.quantity = item.quantity;
          }
          return cartItem;
        });
      }
      setInvalidInput(null);

      // Update the quantity in the cart
      item.quantity = validatedQuantity;

      // Rest of your code for updating cart with discounts, amounts, etc.
      // Recalculate discount based on the updated quantity
      const selectedUnit = selectedUnits[rowIndex] || 1;
      const subtotal = calculateSubT(item, selectedUnit);

      const discount = calculateDiscount(item, selectedUnit);
      const validDiscount = !isNaN(discount) ? discount : 0;

      // Calculate tax on original subtotal
      const tax = (subtotal * item.taxPercentage) / 100;

      // Apply discount to subtotal before adding tax
      const discountedSubtotal = subtotal - validDiscount;

      // Calculate total price with tax on original subtotal and discount applied
      const total = discountedSubtotal + tax;
      updatedCart[rowIndex] = {
        ...item,
        discount,
        amount: total.toFixed(2),
      };

      // Update the discount in the cart
      item.discount = discount;

      return updatedCart;
    });
  };

  const handleChangeRate = (rowIndex, newRate) => {
    console.log(rowIndex, "rowIndex inside handleChangeRate");
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      const item = updatedCart[rowIndex];

      const validatedRate = Math.max(0, parseFloat(newRate) || 0);
      console.log(validatedRate, "validatedRate...");

      setInvalidInput(null);

      item.mrp_price = validatedRate;

      const selectedUnit = selectedUnits[rowIndex] || 1;
      const subtotal = calculateSubT(item, selectedUnit);
      const discount = calculateDiscount(item, selectedUnit);
      const validDiscount = !isNaN(discount) ? discount : 0;

      const tax = (subtotal * item.taxPercentage) / 100;
      const discountedSubtotal = subtotal - validDiscount;
      const total = discountedSubtotal + tax;

      updatedCart[rowIndex] = {
        ...item,
        discount,
        amount: total.toFixed(2),
      };

      return updatedCart;
    });
  };

  /************************* DATA TABLE *************************/

  const style2 = { width: 25, fontSize: 14, padding: 1 };

  const [subTArray, setSubTArray] = useState([]);
  const [unitChangeCount, setUnitChangeCount] = useState(0);
  console.log(subTArray, "subTArray");
  const [selectedUnits, setSelectedUnits] = useState({});
  console.log(selectedUnits, "selectedUnits");

  useEffect(() => {
    const updatedSubTArray = cart.map((item, i) =>
      calculateSubT(item, selectedUnits[i] || 1)
    );
    console.log(updatedSubTArray, "updatedSubTArray");
    setSubTArray(updatedSubTArray);
  }, [cart, unitChangeCount, selectedUnits]);

  // Function to calculate subT for an individual item
  const calculateSubT = (item, selectedUnit) => {
    console.log(item, "propduct-item");
    console.log(selectedUnit, "selectedUnit");
    const quantity = Number(item.quantity);
    const mrpPrice = Number(item.mrp_price);
    const convFactor = Number(item.conv_factor) || 1;

    if (Number(selectedUnit) === 1) {
      return mrpPrice * quantity;
    } else if (Number(selectedUnit) === 2) {
      return (quantity / convFactor) * mrpPrice;
    } else {
      return 0;
    }
  };

  const calculateTax = (item, selectedUnit, index) => {
    console.log(selectedUnit, "selectedUnit..calculateTax");
    const taxPercentage = Number(item.taxPercentage);

    const taxAmount = (subTArray[index] * taxPercentage) / 100;

    return taxAmount.toFixed(2);
  };
  console.log(subTArray, "subTArray");

  // Update the subTArray and cart whenever the unit is changed
  const handleUnitChange = (index, selectedUnit) => {
    setSelectedUnits((prevSelectedUnits) => ({
      ...prevSelectedUnits,
      [index]: selectedUnit,
    }));
    const updatedCart = [...cart];
    const item = updatedCart[index];
    console.log("item received by handleUnitChange: " + JSON.stringify(item));
    console.log(selectedUnits, "handleAddToCart inside handleUnitChange...");

    // Update the subTArray with the new subtotal
    const updatedSubTArray = [...subTArray];
    updatedSubTArray[index] = calculateSubT(item, selectedUnit);
    setSubTArray(updatedSubTArray);
    const subtotal = calculateSubT(item, selectedUnit);

    console.log("updated subT Array: " + updatedSubTArray);
    const discount = calculateDiscount(item, selectedUnit); // Replace with your logic
    const discountPercentage = calculateDiscountPercentage(item, selectedUnit); // Replace with your logic

    const validDiscount = !isNaN(discount) ? discount : 0;

    // Calculate tax on original subtotal
    const tax = (subtotal * item.taxPercentage) / 100;

    // Apply discount to subtotal before adding tax
    const discountedSubtotal = subtotal - validDiscount;

    // Calculate total price with tax on original subtotal and discount applied
    const total = discountedSubtotal + tax;
    console.log(total, "total inside handleUnitChange");
    updatedCart[index] = {
      ...item,
      discount,
      // discountPercentage,
      amount: total.toFixed(2),
      selectedUnit: Number(selectedUnit),
    };

    // You may want to update other properties based on the unit change here
    // Increment the unitChangeCount
    setUnitChangeCount((prevCount) => prevCount + 1);
    console.log(updatedCart, "updatedCart handleUnitChange");
    setCart(updatedCart);

    console.log(cart, "inside handleUnitChange");
  };

  const productColumns = [
    {
      name: "Image",
      selector: (row) => (
        <div className="popover-attribute">
          <OverlayTrigger
            trigger="hover"
            key={row.id} // Make sure each row has a unique key
            placement="right"
            overlay={
              <Popover id={`popover-positioned-${row.id}`}>
                <Popover.Header as="h3">{row.name}</Popover.Header>
                <Popover.Body>
                  {/* <strong>Attribute:</strong> */}
                  <ul>
                    {row.product_attribute_Name[row.product_id] ? (
                      <ul>
                        {Object.entries(
                          row.product_attribute_Name[row.product_id]
                        ).map(
                          ([attribute, value]) =>
                            value && (
                              <li key={attribute}>
                                <strong style={{ fontWeight: "bolder" }}>
                                  {attribute}:
                                </strong>{" "}
                                {value}
                              </li>
                            )
                        )}
                      </ul>
                    ) : (
                      <span>No attributes available</span>
                    )}
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <img
              height="45px"
              width="45px"
              alt={row.name}
              src={row.original_photo_path}
              padding="0"
              style={{ borderRadius: "6px", cursor: "pointer" }}
            />
          </OverlayTrigger>
        </div>
      ),
      // selector: (row) => row.original_photo_path,
      sortable: true,
      center: true,
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      name: "Product",
      selector: (row) => (
        <span style={{ paddingRight: "5px" }}>{row.name}</span>
      ),
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "120px",
    },
    {
      name: "Packing",
      selector: (row) => (
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "normal",
          }}
        >
          {row.packing}
        </span>
      ),
      sortable: true,
      center: true,
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      name: "Unit",
      cell: (row, index) =>
        index !== cart.length ? (
          <Form.Select
            aria-label="Default select example"
            size="sm"
            style={{ width: "80px", padding: "4px", maxHeighteight: "25px" }}
            onChange={(e) => {
              handleUnitChange(index, e.target.value, row);
            }}
          >
            {row.unit_name != "" && (
              <option value="1" selected={row.selectedUnit == 1}>
                {row.unit_name}
              </option>
            )}
            {row.second_unit != "" && (
              <option value="2" selected={row.selectedUnit == 2}>
                {row.second_unit}
              </option>
            )}
          </Form.Select>
        ) : null,
      sortable: true,
      center: true,
      minWidth: "100px",
      maxWidth: "100px",
      padding: "0",
    },
    {
      name: "Qty",
      cell: (row, index) =>
        index != cart.length ? (
          <div
            className={`qty-input-grp d-flex-row${
              invalidInput == index || (showqtyred && row.quantity == 0)
                ? " qty-input-grp-red"
                : ""
            }`}
          >
            {console.log(row, "row inside qty")}
            {console.log(index, "index cart........")}
            {console.log(invalidInput, "invalidInput........")}
            {console.log(invalidInput === index, "invalidInput == index")}
            <Form.Control
              type="number"
              size="sm"
              value={row.quantity.toString()}
              onChange={(e) => handleQuantityInputChange(index, e.target.value)}
              style={{ width: "70px" }}
            />
          </div>
        ) : null,
      selector: (row) => row.quantity,
      sortable: true,
      center: true,
      minWidth: "95px",
      maxWidth: "110px",
    },
    {
      name: "Stock",
      selector: (row) => (
        <span style={{ paddingLeft: "5px" }}>{row.stock}</span>
      ),
      sortable: true,
      center: true,
      minWidth: "65px",
      maxWidth: "65px",
    },
    {
      name: "Rate",
      cell: (row, index) =>
        index !== cart.length ? (
          <Form.Control
            type="number"
            size="sm"
            value={row.mrp_price ? row.mrp_price.toString() : ""}
            onChange={(e) => handleChangeRate(index, e.target.value)}
            style={{ width: "70px" }}
          />
        ) : null,
      sortable: true,
      center: true,
      minWidth: "85px",
      maxWidth: "85px",
    },
    {
      name: (
        <span>
          Gross
          <br />
          Amount
        </span>
      ),
      selector: (row, index) => Number(subTArray[index]).toFixed(2),
      //selector: (row) => (row.mrp_price * row.quantity).toFixed(2),
      sortable: true,
      center: true,
      minWidth: "90px",
      maxWidth: "90px",
    },
    {
      name: (
        <span>
          Disc
          <br />
          (%)
        </span>
      ),
      cell: (row, index) =>
        index !== cart.length /* - 1 */ ? ( // Exclude last item
          <Form.Control
            type="text"
            size="sm"
            value={row.discountPercentage}
            onChange={(e) =>
              handleDiscountChange(index, "percentage", e.target.value)
            }
          />
        ) : null,
      sortable: true,
      center: true,
      minWidth: "85px",
      maxWidth: "85px",
    },
    {
      name: "Disc",
      cell: (row, index) =>
        index !== cart.length /* - 1 */ ? ( // Exclude last item
          <Form.Control
            type="text"
            size="sm"
            value={row.discount}
            onChange={(e) =>
              handleDiscountChange(index, "amount", e.target.value)
            }
            style={{ width: "70px" }}
          />
        ) : null,
      sortable: true,
      center: true,
      minWidth: "75px",
      maxWidth: "75px",
    },
    {
      name: (
        <span>
          Tax
          <br />
          (%)
        </span>
      ),
      selector: (row) => row.taxPercentage,
      sortable: true,
      center: true,
      minWidth: "45px",
      maxWidth: "45px",
    },
    {
      name: "Tax",
      selector: (row, index) => calculateTax(row, selectedUnits[index], index),
      // ((row.mrp_price * row.quantity * row.taxPercentage) / 100).toFixed(2),
      sortable: true,
      center: true,
      minWidth: "65px",
      maxWidth: "65px",
    },
    {
      name: (
        <span>
          Payable
          <br />
          Amount
        </span>
      ),
      selector: (row, index) => row.amount,
      sortable: true,
      center: true,
      minWidth: "70px",
      maxWidth: "150px",
    },
    {
      name: "",
      cell: (row, index) => (
        <div>
          <span>
            <button
              style={{
                width: 25,
                fontSize: 14,
                padding: 1,
                textAlign: "center",
              }}
              color="danger"
              className="btn btn-xs"
              type="button"
              onClick={() => handleRemoveFromCart(row.product_id, index)}
            >
              {/* Click me */}
              <Btn
                attrBtn={{
                  style: style2,
                  color: "danger",
                  className: "btn btn-xs action-button",
                  type: "button",
                }}
                // onClick={() => handleRemoveFromCart(row.product_id)}
              >
                <FiTrash size={15} />
              </Btn>
            </button>
          </span>
        </div>
      ),
      // selector: (row) => row.action,
      sortable: false,
      center: true,
      minWidth: "35px",
      maxWidth: "35px",
    },
  ];

  /************************* BILL SUMMARY *************************/
  const calculateBillSummary = () => {
    const totalItems = cart.length;

    // Calculate actual total price
    const actualTotalPrice = cart.reduce((total, item, index) => {
      return total + subTArray[index];
    }, 0);

    const totalTaxes = cart.reduce((total, item, index) => {
      const taxRate = Number.isFinite(item.taxPercentage)
        ? item.taxPercentage / 100
        : 0;
      const itemTax = subTArray[index] * taxRate || 0;
      return total + itemTax;
    }, 0);

    // Calculate total discount
    const totalDiscount = cart.reduce((total, item) => {
      return total + Number(item.discount);
    }, 0);

    // Calculate overall total by adding taxes first and then subtracting discount
    const overallTotal = Number(actualTotalPrice + totalTaxes - totalDiscount);

    return {
      totalItems,
      actualTotalPrice: Number(actualTotalPrice.toFixed(2)),
      totalDiscount: Number(totalDiscount.toFixed(2)),
      totalTaxes: Number(totalTaxes.toFixed(2)),
      overallTotal: Number(overallTotal.toFixed(2)),
    };
  };

  // State to hold the bill summary
  const [billSummary, setBillSummary] = useState(calculateBillSummary());
  console.log(billSummary, "billSummary.......");

  // Update the bill summary whenever the cart changes
  useEffect(() => {
    // console.log("setBillSummary.....");
    setBillSummary(calculateBillSummary());
  }, [cart, subTArray]);

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  /**************************** tax & disc management ****************************/

  useEffect(() => {
    const updatedCart = cart.map((item, index) => ({
      ...item,
      discount: item.discount || 0,
      discountPercentage: item.discountPercentage || 0,
      amount: calculateAmount(item, selectedUnits[index]),
      taxAmount: calculateTax(item, item.selectedUnit, index),
      subT: subTArray[index] || 0, // Assuming subTArray has values for each index
    }));

    // Check if the cart has actually changed before updating the state
    if (!deepEqual(updatedCart, cart)) {
      console.log(updatedCart, "updatedCart inside useeefect");
      setCart(updatedCart);
      console.log(cart, "inside useeefect cart amount updtae....");
    }
  }, [cart, subTArray]);

  const handleDiscountChange = (index, type, value) => {
    const updatedCart = [...cart];
    const item = updatedCart[index];

    if (value && value != "0") {
      value = value.startsWith("0") ? value.slice(1) : value;
    }

    // Handle backspacing to prevent NaN
    if (value == "" || value == null || isNaN(value)) {
      value = "0";
    }

    if (type == "percentage") {
      item.discountPercentage = value;
      item.discount = calculateDiscount(item, selectedUnits[index]);
    } else if (type == "amount") {
      item.discount = value;
      item.discountPercentage = calculateDiscountPercentage(
        item,
        selectedUnits[index]
      );
    }
    console.log(index, "inside handleDiscountChange");
    item.amount = calculateAmount(item, selectedUnits[index]);
    console.log(item.amount, "item_amount");
    setCart(updatedCart);
  };

  // Apply discount to subtotal first, then add tax
  const calculateDiscount = (row, selectedUnit) => {
    console.log(selectedUnit, "umang check");
    const subtotal = calculateSubT(row, selectedUnit);
    console.log(subtotal, "calculateDiscount");
    return (
      ((subtotal * parseFloat(row.discountPercentage)) / 100).toFixed(2) || 0
    );
  };

  const calculateDiscountPercentage = (row, selectedUnits) => {
    const subtotal = calculateSubT(row, selectedUnits);

    // Prevent division by zero
    if (subtotal === 0) {
      return 0;
    }

    return ((parseFloat(row.discount) * 100) / subtotal).toFixed(2) || 0;
  };

  const calculateAmount = (item, selectedunit) => {
    console.log(selectedunit, "inside calculateAmount");
    const subtotal = calculateSubT(item, selectedunit);
    const discount = calculateDiscount(item, selectedunit); // Use existing discount calculation
    console.log(subtotal, "subtotal calculateAmount");
    console.log(discount, "discount calculateAmount");

    // Validate discount and ensure it is a valid number
    const validDiscount = !isNaN(discount) ? discount : 0;

    // Calculate tax on original subtotal
    const tax = (subtotal * item.taxPercentage) / 100;

    // Apply discount to subtotal before adding tax
    const discountedSubtotal = subtotal - validDiscount;

    // Calculate total price with tax on original subtotal and discount applied
    const total = discountedSubtotal + tax;

    return !isNaN(total) ? total.toFixed(2) : "0.00";
  };

  const [Invoice_no, setInvoiceno] = useState("");
  const [Invoice_fullno, setInvoicefullno] = useState("");
  const [showqtyred, setShowqtyred] = useState(false);

  const Insertinvoicedata = async (data) => {
    setSavebtn(true);

    if (cart.length === 0) {
      // Show error message for empty cart
      toast.error("Please Select at least One Product.", {
        position: "top-right",
        autoClose: 5000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setSavebtn(false);
      return;
    }

    const invalidProducts = cart.filter((product) => product.quantity <= 0);

    if (invalidProducts.length > 0) {
      setShowqtyred(true);
      // Show error message for products with quantity 0
      const errorMessage = `Quantity should be greater than 0 for the following product(s): ${invalidProducts
        .map((product) => product.name)
        .join(", ")}`;

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setSavebtn(false);
      return;
    }

    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/insertInvoiceData/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          invoice_amount: billSummary?.actualTotalPrice,
          invoice_discount: billSummary?.totalDiscount,
          invoice_tax: billSummary?.totalTaxes,
          grand_total: billSummary?.overallTotal,
          contact_id: contact_id || "",
          biiling_addres_id: Billingaddress_id,
          shipping_addres_id: Shippingaddress_id,
          products: cart,
        }
      );

      if (res && res?.data && res?.data[0]?.DATA) {
        setInvoiceno(res?.data[0]?.DATA);
        setInvoicefullno(res?.data[0]?.fullinvoice_no);
        toast.success(res?.data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setShowqtyred(false);
        setSavebtn(true);
        setPrintbtn(false);
        setWhatsappbtn(false);
      } else {
        setShowqtyred(false);
        toast.error(res?.data[0]?.MSG, {
          position: "top-right",
          autoClose: 5000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setSavebtn(false);
        setPrintbtn(false);
        setWhatsappbtn(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSavebtn(false);
      setShowqtyred(false);
    }
  };

  console.log(category_info, "category_info..");
  console.log(categoryData, "categoryData...");
  console.log(categoryProducts, "categoryProducts...");
  console.log(product_info, "product_info...");
  console.log(displayFilteredItems, "displayFilteredItems...");

  const fetchProductsForMainCategory = async (item, index) => {
    setIsSelected(index);
    setProductinfo("Data Found");
    setDisplayFilteredItems([]);
    if (item?.Children?.length > 0) {
      // If the category has children, set the children in the state
      setCategoryData(item?.Children);
      setCategoryinfo("Data Found");
    } else {
      // If the category has no children, set an empty array in the state
      setCategoryinfo("Data Not Found");
      setCategoryData([]);
    }

    try {
      const response = await axios.post(
        "/ecommerce_api/getCategorywiseProductList/",
        {
          category_name: item?.name,
        }
      );

      if (response.data[0].STATUS == "SUCCESS") {
        const data = response.data[0]?.DATA;
        const fetchedItems = data ? data.products : [];
        setCategoryProducts(fetchedItems);
        setDisplayFilteredItems(fetchedItems);
        setProductinfo("Data Found");
        setIsSelected(index);
      } else {
        console.error("Error fetching data:", response.data[0].MSG);
        // Handle error, set error state, show a user-friendly message, etc.
        setAllProducts([]);
        setCategoryProducts([]);
        setDisplayFilteredItems([]);
        setProductinfo("Data Not Found");
        setIsSelected(index);
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getCategorywiseProductList API:", error);
      // Set an error state or show a user-friendly message
      setCategoryProducts([]);
      setDisplayFilteredItems([]);
      setProductinfo("Data Not Found");
    } finally {
      setLoading(false);
    }
  };

  const handleClickAll = async () => {
    // Call both functions when clicking on "ALL"
    try {
      // Fetch subcategories
      await fetchSubcategoriesforAllbutton();

      // Fetch data for "ALL" category
      await fetchAllProductsforAllbutton();
    } catch (error) {
      console.error("Error in handleClickAll:", error);
      // Handle error, set error state, show a user-friendly message, etc.
    }
  };

  const fetchSubcategoriesforAllbutton = async () => {
    setCategoryData([]);
    setCategoryinfo("Data found");
    try {
      const response = await axios.post(
        "/ecommerce_api/getProductCategoryList/",
        {
          tree: false,
          AUTHORIZEKEY: AUTHORIZE_KEY,
          PHPTOKEN: localStorage.getItem("id_token"),
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        console.log("product category list:", response.data[0].DATA);
        setCategoryData(response.data[0].DATA.categorylist);
        setCategoryinfo("Data found");
      } else {
        setCategoryData([]);
        setCategoryinfo("Data not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getProductCategoryList API:", error);
      // You can also set an error state or show a user-friendly message
      // setErrorState(true);
    }
  };

  const fetchAllProductsforAllbutton = async () => {
    setProductinfo("Data Found");
    setDisplayFilteredItems([]);
    try {
      const response = await axios.post(
        "/ecommerce_api/getCategorywiseProductList/",
        {
          category_name: "ALL",
        }
      );

      if (response.data[0].STATUS === "SUCCESS") {
        const data = response.data[0]?.DATA;
        const filteredItems = data ? data.products : [];

        if (filteredItems && filteredItems.length > 0) {
          setAllProducts(filteredItems);
          setCategoryProducts(filteredItems);
          setDisplayFilteredItems(filteredItems);
          setProductinfo("Data Found");
          console.log("ALL products:", filteredItems);
        } else {
          setProductinfo("Data not Found");
          setAllProducts([]);
          setCategoryProducts([]);
          setDisplayFilteredItems([]);
        }
      } else {
        console.error("Error fetching data:", response.data[0].MSG);
        // Handle error, set error state, show a user-friendly message, etc.
      }
    } catch (error) {
      // Handle the error
      console.error("Error in getCategorywiseProductList API:", error);
      setCategoryProducts([]);
      setDisplayFilteredItems([]);
      setProductinfo("Data Not Found");
      // Set an error state or show a user-friendly message
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const componentRef = useRef();
  console.log(componentRef, "componentRef....");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrintbtn(false); // Enable the Print button after printing
    },
  });

  const currenttodaydate = moment().format("DD-MM-YYYY"); // Using moment to get the current date
  const [Printbtn, setPrintbtn] = useState(true);
  const [whatsappbtn, setWhatsappbtn] = useState(true);
  const [showgeneratetext, setShowgeneratetext] = useState(false);

  return (
    <>
      <div className="mt-4 mx-2">
        {/* navbar start */}
        {MaincategoryData.length > 0 && (
          <nav className="navbar-pos">
            <ul className="navbar-nav-pos">
              <li
                className={`dropdown-item-pos ${
                  selectedMaincategory == "ALL" ? "selected-item" : ""
                }`}
                onClick={() => {
                  setSelectedMaincategory("ALL");
                  handleClickAll();
                }}
              >
                ALL
              </li>
              {MaincategoryData.slice(0, 7).map((item, index) => {
                return (
                  <li
                    className={`dropdown-item-pos ${
                      selectedMaincategory == item?.name ? "selected-item" : ""
                    }`}
                    onClick={() => {
                      console.log(item?.Children, "children categories");
                      // handleMainCategoryClick(item,index);
                      setSelectedMaincategory(item?.name);
                      fetchProductsForMainCategory(item, index);
                    }}
                  >
                    {item?.name}
                  </li>
                );
              })}
              {MaincategoryData.length > 7 && (
                <li
                  className={`nav-item dropdown-more dropdown-item-pos ${
                    selectedMaincategory == "More" ? "selected-item" : ""
                  }`}
                  onClick={handleMoreClick}
                >
                  <button className="dropdown-toggle">More</button>
                  {showMore && (
                    <div className="dropdown-content">
                      {/* Your dropdown items */}
                      <ul>
                        {MaincategoryData.slice(7).map((item, index) => (
                          <React.Fragment key={index}>
                            <li
                              // className={`dropdown-item-pos ${
                              //   selectedMaincategory == item.name ? "selected-item" : ""
                              // }`}
                              onClick={() => {
                                console.log(
                                  item?.Children,
                                  "children categories"
                                );
                                fetchProductsForMainCategory(item, index);
                              }}
                            >
                              {item.name}
                            </li>
                            {index < MaincategoryData.slice(7).length - 1 && (
                              <hr className="more-horizontal" />
                            )}
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </nav>
        )}

        {/* navbar end  */}
        <Row className="my-grid mt-2">
          <Col
            className={`${
              categoriesToDisplay.length < 12
                ? " vertical-line"
                : "overflow-y-auto custom-scrollbar"
            }`}
          >
            <Form className="mt-1">
              <FormGroup
                className="m-0 form-group"
                // style={{ width: 150, height: 40 }}
              >
                <div className="productSearchInputGroup">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="search categories..."
                    value={categorySearchQuery}
                    onChange={handleCategorySearchChange}
                  />
                  {/* <i
                      className="fa fa-search"
                      // onClick={() => setSearchQuery(searchQuery)}
                    ></i> */}
                </div>
              </FormGroup>
            </Form>
            <div className="d-flex flex-column mt-2 ">
              <div>
                {category_info == "Data Not Found" &&
                categoriesToDisplay.length == 0 ? (
                  <div className="center-nocategory vertical-line">
                    No categories found
                  </div>
                ) : null}
                {categoriesToDisplay && categoriesToDisplay.length > 0
                  ? categoriesToDisplay.map((item) => (
                      <div
                        id="cat-gridId"
                        className={`cat-gridId ${
                          selectedCategory == item.name ? "selected" : ""
                        } d-flex-row hoverable`}
                        key={item.id}
                        onClick={() => handleCategoryClick(item.name)}
                      >
                        <img
                          src={
                            item.full_image_path
                              ? item.full_image_path
                              : "https://r1services.ondemandcrm.co/public/images/ecommerce/productnotfound.png"
                          }
                          alt={item.name}
                          className="cat-img"
                          style={{ marginLeft: "2px" }}
                        />
                        <div className="categoryItemContainer">
                          <p
                            className={`category-name ${
                              selectedCategory === item.name ? "selected" : ""
                            } `}
                          >
                            {item.name}
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
                {/* Display Spinner only if data is being fetched */}
                {category_info != "Data Not Found" &&
                  categoriesToDisplay.length == 0 && (
                    <div className="text-center mt-3">
                      <Spinner animation="border" variant="success" />
                    </div>
                  )}
              </div>
            </div>
          </Col>

          {/* Product display */}
          <Col
            className={`${
              displayFilteredItems.length > 12
                ? " overflow-y-auto custom-scrollbar "
                : "vertical-line"
            }`}
          >
            <div className=" d-flex-col">
              <Form className="mt-1">
                <FormGroup
                  className="m-0 form-group search-product"
                  // style={{ width: 400, height: 40 }}
                >
                  <div className="productSearchInputGroup">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="search products..."
                      defaultValue={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />

                    <i
                      className="fa fa-search"
                      onClick={() => setSearchQuery(searchQuery)}
                    ></i>
                  </div>
                </FormGroup>
              </Form>
              <div className="d-flex flex-wrap mt-2">
                {product_info == "Data Not Found" &&
                displayFilteredItems.length == 0 ? (
                  <div
                    className="loaderContainer d-flex-col vertical-line"
                    style={{ minHeight: "70vh" }}
                  >
                    No products found
                  </div>
                ) : null}
                {displayFilteredItems && displayFilteredItems.length > 0
                  ? displayFilteredItems.map((item, index) => (
                      <div
                        id="prod-gridId"
                        className="product-img d-flex-col center hoverable"
                        key={item.product_id}
                        onClick={() => {
                          Openatrributemodal(
                            item,
                            item.product_id,
                            item?.name,
                            index
                          );
                        }}
                      >
                        <Image
                          attrImage={{
                            className: "img-fluid prod-img",
                            src: item?.original_photo_path
                              ? item.original_photo_path
                              : "https://r1services.ondemandcrm.co/public/images/ecommerce/productnotfound.png",
                            alt: item?.name || "",
                          }}
                        />

                        <p className="product-name">{item.name}</p>
                      </div>
                    ))
                  : null}
                {product_info != "Data Not Found" &&
                displayFilteredItems.length == 0 ? (
                  <div className="loaderContainer d-flex-col">
                    <Spinner animation="border" variant="success" />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>

          {/* Product qty portion discount portion */}
          <Col>
            <div className="d-flex-col">
              <div className="rightMenu d-flex-col">
                <Row>
                  <Col md={4}>
                    <div className="shippingCheckboxContainer d-flex-row invisible">
                      {addCustomerDetails && showBillingModal && (
                        <>
                          <label for="shippingCheckbox">
                            Shipping Same as Billing
                          </label>
                          <input
                            type="checkbox"
                            id="shippingCheckbox"
                            name="shippingCheckbox"
                            checked={sameAsBilling} // Initially checked
                            onChange={handleCheckboxChange}
                          />
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="shippingCheckboxContainer d-flex-row">
                      {addCustomerDetails && showBillingModal && (
                        <>
                          <label for="shippingCheckbox">
                            Shipping Same as Billing
                          </label>
                          <input
                            type="checkbox"
                            id="shippingCheckbox"
                            name="shippingCheckbox"
                            checked={sameAsBilling} // Initially checked
                            onChange={handleCheckboxChange}
                          />
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md={4} className="customerdetail-switch mt-1">
                    <div className="form-check form-switch cust-toggle-line d-flex-row">
                      <label
                        className="form-check-label"
                        htmlFor="toggleSwitch"
                      >
                        <MdContactPhone className="contact-icon me-1" />
                        Add Customer Details
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="toggleSwitch"
                        checked={addCustomerDetails}
                        onChange={() => {
                          setAddCustomerDetails(!addCustomerDetails);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {/* should hide and show acc. to weather contact selected */}

                {addCustomerDetails && (
                  <>
                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={10} style={{ paddingRight: "0px" }}>
                            <div className="d-flex-col">
                              <ReactSelect
                                options={contacts
                                  .filter((contact) => contact.name) // Exclude options without a name
                                  .map((contact) => ({
                                    value: contact.id,
                                    label: `${contact.name} - ${contact.mobile}`,
                                    email: contact.email,
                                    mobile: contact.mobile,
                                    contactname: contact.name,
                                  }))}
                                // isClearable={true}
                                components={animatedComponents}
                                menuPortalTarget={document.body} // Render the dropdown outside parent containers
                                // styles={customStyles}
                                value={
                                  selectedContact
                                    ? {
                                        value: selectedContact.id,
                                        label: `${selectedContact.name} - ${selectedContact.mobile}`,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  // setContactid(selectedOption.value); // Update the contact_id state
                                  handleSelectChange(selectedOption);
                                }}
                              />

                              <div className="d-flex contact-group mt-1">
                                <p className="contact-p">Contact</p>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <Button
                              variant="primary"
                              className="mt-2 p-1 btn-sm d-flex align-items-center contact-button"
                              onClick={togglecontact}
                            >
                              <AiOutlinePlus size={15} />
                            </Button>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={4}>
                        {showBillingModal && (
                          <>
                            <Row>
                              <Col md={10} style={{ paddingRight: "0px" }}>
                                <div className="d-flex-col">
                                  <select
                                    className="form-select form-control form-control-sm"
                                    id="sel_address"
                                    name="sel_address"
                                    // style={{ width: "250px", height: "40px" }}
                                    onChange={(e) =>
                                      setBillingaddressId(e.target.value)
                                    }
                                  >
                                    {Address.map((option) => (
                                      <option
                                        key={option.address_id}
                                        value={option.address_id}
                                        selected={
                                          Billingaddress_id != ""
                                            ? Billingaddress_id ==
                                              option.address_id
                                            : option ==
                                              Address.find(
                                                (address) =>
                                                  address.billing_flg == "Y"
                                              )
                                        }
                                      >
                                        {option.address_1
                                          ? option.address_1.slice(0, 25)
                                          : "No Address found"}
                                      </option>
                                    ))}
                                  </select>

                                  <div className="d-flex billing-group mt-1">
                                    <p className="billing-p">Billing address</p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={2}>
                                <div>
                                  <Button
                                    variant="primary"
                                    className="mt-2 p-1 btn-sm d-flex align-items-center billing-button"
                                    onClick={togglebilling}
                                  >
                                    <AiOutlinePlus size={15} />
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>

                      <Col md={4}>
                        {showShippingModal && (
                          <>
                            <Row>
                              <Col md={10} style={{ paddingRight: "0px" }}>
                                <div className="d-flex-col">
                                  <Form.Select
                                    aria-label="Second select example"
                                    // style={{ width: "250px", height: "40px" }}
                                    onChange={(e) =>
                                      setShippingaddressId(e.target.value)
                                    }
                                  >
                                    {Address.map((option) => (
                                      <option
                                        key={option.address_id}
                                        value={option.address_id}
                                        selected={
                                          Shippingaddress_id != ""
                                            ? Shippingaddress_id ==
                                              option.address_id
                                            : option ==
                                              Address.find(
                                                (address) =>
                                                  address.billing_flg == "Y"
                                              )
                                        }
                                      >
                                        {option.address_1
                                          ? option.address_1.slice(0, 25)
                                          : "No Address found"}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  <div className="shipping-group d-flex-row mt-1">
                                    <p className="shipping-p">
                                      Shipping address
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={2} style={{ paddingRight: "0px" }}>
                                <div>
                                  {/* <Button
                                  variant="primary"
                                  className="mt-2 p-1 btn-sm d-flex align-items-center shipping-button"
                                  onClick={toggleshipping}
                                >
                                  <AiOutlinePlus size={15} />
                                </Button> */}
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mb-3">
                  <Col md={4}>
                    <Form className="mt-1">
                      <FormGroup
                        className="m-0 form-group search-product"
                        // style={{ width: 400, height: 40 }}
                      >
                        <div className="productSearchInputGroup barcode-input">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="search barcode..."
                            defaultValue={searchBarcode}
                            onChange={(e) => {
                              setSearchBarcode(e.target.value);
                              // getProductByBarcode(e.target.value);
                            }}
                          />

                          <i
                            className="fa fa-search"
                            onClick={() => setSearchBarcode(searchBarcode)}
                          ></i>
                        </div>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>

                {/* draft code */}

                <Modal
                  isOpen={isOpen}
                  toggle={() => dispatch(toggleModal())}
                  className="draft-modal-lg"
                  size="xl"
                  backdrop="static"
                  keyboard={false}
                >
                  <ModalHeader toggle={() => dispatch(toggleModal())}>
                    All Draft Orders
                  </ModalHeader>
                  <ModalBody className="">
                    <Row className="gy-3 gx-3">
                      {drafts.length == 0 ? (
                        <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                          No draft orders available.
                        </p>
                      ) : (
                        drafts.map((draft, index) => (
                          <Col
                            key={index}
                            md={3}
                            onClick={() => {
                              handleEdit(draft, drafts);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="draftBox d-flex-col">
                              <p className="draftNumber mb-0">{`Order #${
                                index + 1
                              }`}</p>
                              <p className="draftDetails-p">
                                <span
                                  style={{ fontWeight: "bold", color: "black" }}
                                >{`Contact: `}</span>
                                {`${draft?.contactName && draft?.contactName}`}
                              </p>
                              <p
                                className="draftDetails-p "
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {`Date and Time: `}
                              </p>
                              <p className="mb-0">
                                {`${draft?.currentDateAndTime}`}
                                {/* Assuming totalAmount is the variable containing the sum */}
                              </p>
                              <p className="draftDetails-p">
                                <span
                                  style={{ fontWeight: "bold", color: "black" }}
                                >{`Mobile No: `}</span>
                                {`${
                                  draft?.contactMobileNumber &&
                                  draft?.contactMobileNumber
                                }`}
                              </p>
                              <p className="draftDetails-p">
                                <span
                                  style={{ fontWeight: "bold", color: "black" }}
                                >{`Total Products: `}</span>
                                {`${draft?.numberOfItems}`}
                              </p>

                              <p className="draftDetails-p">
                                <span
                                  style={{ fontWeight: "bold", color: "black" }}
                                >{`Total Quantity: `}</span>
                                {`${draft?.TotalQuantity}`}
                                {/* ${parseFloat(draft.total).toFixed(2)} */}
                              </p>

                              <p className="draftDetails-p draft-total-amt">
                                {`Amount to Pay: `}
                              </p>
                              <p
                                style={{
                                  marginTop: "2px",
                                  fontSize: "large",
                                  fontWeight: "bold",
                                  color: "green",
                                }}
                                className="mb-0"
                              >
                                {`₹${parseFloat(draft.total).toFixed(2)}`}{" "}
                                {/* Assuming totalAmount is the variable containing the sum */}
                              </p>
                              <div className="d-flex justify-content-end">
                                <div>
                                  <Button
                                    className="modal-button"
                                    variant="info"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the click from propagating to the parent Col
                                      handleEdit(draft, drafts);
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                </div>
                                <div className="ms-2">
                                  <Button
                                    className="modal-button"
                                    variant="danger"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the click from propagating to the parent Col
                                      handleDelete(draft, drafts);
                                    }}
                                  >
                                    <RiDeleteBin7Fill />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))
                      )}
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={() => dispatch(toggleModal())}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <div className="billContainer d-flex-col">
                <div className="billContainerBody d-flex-col">
                  <div className="bill">
                    {cart_info == "Data not found" && cart.length == 0 ? (
                      <div
                        className="text-center mt-3 py-3"
                        style={{ backgroundColor: "#dfdcdc" }}
                      >
                        There are no records to display
                      </div>
                    ) : cart_loading ? (
                      <div className="text-center pt-3 pb-2">
                        <Spinner animation="border" variant="success" />
                      </div>
                    ) : (
                      <DataTable
                        noHeader
                        paginationServer
                        columns={productColumns}
                        data={cart}
                        highlightOnHover={true}
                        striped={true}
                        responsive={true}
                        persistTableHead={true}
                      />
                    )}
                  </div>
                </div>
                <div className="extraDetailsContainer d-flex-col">
                  <div className="resultContainer d-flex-row">
                    <table className="resultTable box">
                      <thead>
                        <tr>
                          <th colSpan="2">Bill Summary</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Products:</td>
                          <td>{billSummary.totalItems}</td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid #ddd" }}>
                          <td>Total Gross Amount:</td>
                          <td>₹{billSummary.actualTotalPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Total Discount:</td>
                          <td>₹{billSummary.totalDiscount.toFixed(2)}</td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid #ddd" }}>
                          <td>Total Taxes:</td>
                          <td>₹{billSummary.totalTaxes.toFixed(2)}</td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid #ddd" }}>
                          <td>Total Payable Amount:</td>
                          <td
                            style={{
                              fontSize: "larger",
                              color: "green",
                              fontWeight: "500",
                            }}
                          >
                            ₹{billSummary.overallTotal.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="buttonsBillContainer d-flex-row">
                    <button
                      type="button"
                      class="btn btn-info new-btn buttonBill save d-flex-row"
                      onClick={() => {
                        setContactid("");
                        setContactname("");
                        setContactnumber("");
                        setBillingaddressId("");
                        setShippingaddressId("");
                        setAddress([]);
                        setSameAsBilling(true);
                        setShowShippingModal(false);
                        setShowBillingModal(false);
                        setAddCustomerDetails(false);
                        setCart([]);
                        setSavebtn(false);
                        setPrintbtn(true);
                        setWhatsappbtn(true);
                        // setSavebtn(false);
                        setCartinfo("Data not found");
                        setInvoiceno("");
                        setInvoicefullno("");
                      }}
                    >
                      <div className="svgButtonContainer d-flex-row">
                        {/* <FaPrint className="bill-button-svg" size={20} /> */}
                        <MdCreateNewFolder
                          className="bill-button-svg"
                          size={20}
                        />
                      </div>
                      <span className="bill-button-text">New</span>
                    </button>

                    <button
                      type="button"
                      class="btn btn-warning  buttonBill draft d-flex-row"
                      onClick={() => {
                        if (cart.length > 0) {
                          // added by umang 25/01/2024
                          const total = cart.reduce(
                            (acc, item) => acc + parseFloat(item.amount),
                            0
                          );

                          const totalQuantity = cart.reduce((acc, item) => {
                            return acc + item.quantity;
                          }, 0);

                          // Add the draft with additional information
                          addDraft({
                            items: cart,
                            numberOfItems: cart?.length,
                            contactName:
                              addCustomerDetails && contact_name != ""
                                ? contact_name
                                : "", // Replace with actual customer name
                            contactMobileNumber:
                              addCustomerDetails && contact_number != ""
                                ? contact_number
                                : "",
                            contactid:
                              addCustomerDetails && contact_id != ""
                                ? contact_id
                                : "",
                            BillingaddressId: Billingaddress_id,
                            ShippingaddressId: Shippingaddress_id,
                            Ship_Bill_Same: sameAsBilling,
                            total: total, // Total calculated based on cart items
                            currentDateAndTime: moment().format(
                              "MMMM Do YYYY, h:mm:ss a"
                            ),
                            TotalQuantity: totalQuantity,
                            /* other draft information */
                          });
                          clearCart();
                          notifyDraft();
                          setContactid("");
                          setContactname("");
                          setContactnumber("");
                          setBillingaddressId("");
                          setShippingaddressId("");
                          setAddress([]);
                          setSameAsBilling(true);
                          setShowShippingModal(false);
                          setShowBillingModal(false);
                          setAddCustomerDetails(false);
                          setCartinfo("Data not found");
                        } else {
                          toast.error("Please Select Product", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }
                      }}
                    >
                      <div className="svgButtonContainer d-flex-row">
                        {/* <IoArchive className="bill-button-svg" size={20} /> */}
                        <MdDrafts className="bill-button-svg" size={20} />
                      </div>
                      <span className="bill-button-text">Draft</span>
                    </button>

                    <button
                      type="button"
                      class="btn btn-success buttonBill save d-flex-row"
                      onClick={() => Insertinvoicedata()}
                      disabled={Savebtn}
                    >
                      <div className="svgButtonContainer d-flex-row">
                        {/* <FaPrint className="bill-button-svg" size={20} /> */}
                        <FaSave className="bill-button-svg" size={20} />
                      </div>
                      <span className="bill-button-text">Save</span>
                    </button>

                    <button
                      type="button"
                      className="btn btn-gradient buttonBill save d-flex-row"
                      onClick={() => {
                        handlePrint();
                        setPrintbtn(true);
                      }}
                      disabled={Printbtn}
                    >
                      <div className="svgButtonContainer d-flex-row">
                        <FaPrint className="bill-button-svg" size={20} />
                      </div>
                      <span className="bill-button-text">Print</span>
                    </button>

                    <div className="d-flex flex-column">
                      <button
                        type="button"
                        className="btn btn-success buttonBill save d-flex-row"
                        onClick={() => {
                          generatePDF();
                          setWhatsappbtn(true);
                          setShowgeneratetext(true);
                        }}
                        // disabled={whatsappbtn}
                      >
                        <div className="svgButtonContainer d-flex-row">
                          <FaWhatsapp className="bill-button-svg" size={23} />
                        </div>
                        <span className="bill-button-text">Whatsapp</span>
                      </button>
                    </div>
                  </div>
                </div>
                {showgeneratetext && (
                  <div className="text-center timesheet-label mt-3">
                    Please wait while sending message...
                  </div>
                )}

                <div
                  style={{
                    height: 0,
                    overflow: "hidden",
                    visibility: "hidden",
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "8px", marginLeft: "25px" }}
                    ref={componentRef}
                    id="rep1"
                  >
                    <div className="receipt">
                      <div className="text-center">
                        <img
                          className="img-fluid for-light my-2"
                          src={`${baseurl}${image_url_logo}`}
                          alt="company_logo"
                        />
                      </div>
                      <p>
                        <b>Name: </b>
                        {contact_name || "Cash Contact"}
                      </p>
                      <p>
                        <b>Mobile: </b>
                        {contact_number || "9824023886"}
                      </p>
                      <hr className="my-2" />
                      <p>
                        <b>Invoice No: </b>
                        {Invoice_fullno}
                      </p>
                      <p>
                        <b>Date: </b>
                        {currenttodaydate}
                      </p>
                      <hr className="my-2" />
                      <table>
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart?.length > 0 &&
                            cart?.map((item) => (
                              <tr key={item.product_id}>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>
                                  <span className="inr-font">INR </span>
                                  {item?.subT?.toFixed(2)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan="2">Total Groos Amount</th>
                            <td>
                              <span className="inr-font">INR </span>
                              {billSummary?.actualTotalPrice}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">Total Discount Amount</th>
                            <td>
                              <span className="inr-font">INR </span>
                              {billSummary?.totalDiscount}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">Total Taxes Amount</th>
                            <td>
                              <span className="inr-font">INR </span>
                              {billSummary?.totalTaxes}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">Total Payable Amount</th>
                            <td>
                              <span className="inr-font">INR </span>
                              {(
                                billSummary?.actualTotalPrice -
                                billSummary?.totalDiscount +
                                billSummary?.totalTaxes
                              )?.toFixed(2)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <hr className="my-2" />
                      <p>Thank you for your business!</p>
                    </div>
                  </div>
                </div>
                {/* pdf view end  */}

                {/* Contact name  */}
                <Modal
                  isOpen={contactmodal}
                  toggle={togglecontact}
                  size="lg"
                  backdrop="static"
                  keyboard={false}
                >
                  <ModalHeader toggle={togglecontact}>Contact name</ModalHeader>
                  <Form onSubmit={handleSubmit(handleSubmitContact)}>
                    <ModalBody className="grid-showcase">
                      <Container fluid={true} className="bd-example-row">
                        <Row className="mb-3">
                          <Col xs={4} md={4}>
                            <Form.Group>
                              <Form.Label>
                                <b>Name</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                id="name"
                                name="name"
                                {...register("name", {
                                  required: true,
                                  maxLength: 30,
                                })}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={4} md={4}>
                            <Form.Group>
                              <Form.Label>
                                <b>Mobile No</b>
                              </Form.Label>
                              <Form.Control
                                type="tel"
                                placeholder="Enter Mobile Number"
                                id="mobile"
                                name="mobile"
                                {...register("mobile", {
                                  required: "Mobile number is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message:
                                      "Enter a valid 10-digit mobile number",
                                  },
                                })}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={4} md={4}>
                            <Form.Group>
                              <Form.Label>
                                <b>Email</b>
                              </Form.Label>
                              <Form.Control
                                type="email" // Change the type to "password"
                                placeholder="Enter email"
                                name="email"
                                {...register("email")}
                                // required
                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" // Email format validation pattern
                                title="Please enter a valid email address" // Error message for invalid email format
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Line 1</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address1"
                                {...register("address1")}
                                // required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Line 2</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address2"
                                {...register("address2")}
                                // required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={3} md={3}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Type</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address_type"
                                {...register("address_type")}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={3} md={3}>
                            <Form.Group>
                              <Form.Label>
                                <b>Area</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="area"
                                {...register("area")}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={3} md={3}>
                            <Form.Group>
                              <Form.Label>
                                <b>City</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="city"
                                {...register("city")}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={3} md={3}>
                            <Form.Group>
                              <Form.Label>
                                <b>Pincode</b>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="zipcode"
                                {...register("zipcode")}
                                maxLength={6} // Set the maximum length to 6 digits
                                onKeyDown={(e) => {
                                  if (
                                    e.target.value.length >= 6 &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault(); // Prevent further input after 6 digits
                                  }
                                }}
                                // required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group controlId="sel_country">
                              <Form.Label>
                                <b>Country</b>
                              </Form.Label>
                              <Form.Select
                                name="country"
                                {...register("country", { required: true })}
                                onChange={(e) => {
                                  changestate(e, 0);
                                }}
                                // required
                              >
                                <option value=""> -- Select Country -- </option>
                                {country.map((cntry) => (
                                  <option
                                    key={cntry.Country.country_code}
                                    value={cntry.Country.country_name}
                                  >
                                    {cntry.Country.country_name}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a country.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>State</b>
                              </Form.Label>
                              <Form.Select
                                name="state"
                                {...register("state", { required: true })}
                                onChange={(e) => {
                                  setAddContact({
                                    ...addcontact,
                                    state: e.target.value,
                                  });
                                }}
                                // required
                              >
                                <option value=""> -- Select State -- </option>
                                {State
                                  ? [
                                      State.map((S) => (
                                        <option
                                          key={`state_${S.State.state_name}`}
                                          value={S.State.state_name}
                                        >
                                          {S.State.state_name}
                                        </option>
                                      )),
                                    ]
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a state.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Btn
                        attrBtn={{ color: "secondary", onClick: togglecontact }}
                      >
                        Close
                      </Btn>
                      <Btn
                        attrBtn={{ color: "primary", disabled: contactbtn }}
                        type="submit"
                      >
                        SaveChanges
                      </Btn>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Billing address  */}
                <Modal
                  isOpen={billingmodal}
                  toggle={togglebilling}
                  size="lg"
                  backdrop="static"
                  keyboard={false}
                >
                  <ModalHeader toggle={togglebilling}>
                    Billing Address
                  </ModalHeader>
                  <Form onSubmit={(e) => handleSubmitAddressBilling(e)}>
                    {" "}
                    <ModalBody className="grid-showcase">
                      <Container fluid={true} className="bd-example-row">
                        <Row className="mb-3">
                          <Col xs={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Type</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_add_type_new_billing"
                                name="txt_add_type_new_billing"
                                value={nickname_billing}
                                onChange={handleChangeFieldbilling}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Line 1</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_add_new1_billing"
                                name="txt_add_new1_billing"
                                value={address1_billing}
                                onChange={handleChangeFieldbilling}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Addrss Line 2</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_add_new2_billing"
                                name="txt_add_new2_billing"
                                value={address2_billing}
                                onChange={handleChangeFieldbilling}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Area</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_area_new_billing"
                                name="txt_area_new_billing"
                                value={area_billing}
                                onChange={handleChangeFieldbilling}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>City</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_city_new_billing"
                                name="txt_city_new_billing"
                                value={city_billing}
                                onChange={handleChangeFieldbilling}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group controlId="sel_country_new">
                              <Form.Label>
                                <b>Country</b>
                              </Form.Label>
                              <Form.Select
                                id="sel_country_new_billing"
                                name="sel_country_new_billing"
                                onChange={(e) => {
                                  changestate(e, 0);
                                }}
                                value={selcountry_billing}
                                required
                              >
                                <option value=""> -- Select Country -- </option>
                                {country
                                  ? [
                                      country.map((C) => (
                                        <option
                                          key={`country_${C.Country.country_code}`}
                                          value={C.Country.country_name}
                                        >
                                          {C.Country.country_name}
                                        </option>
                                      )),
                                    ]
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a country.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group controlId="sel_state_new">
                              <Form.Label>
                                <b>State</b>
                              </Form.Label>
                              <Form.Select
                                id="sel_state_new_billing"
                                name="sel_state_new_billing"
                                value={selstate_billing}
                                onChange={handleChangeFieldbilling}
                                required
                              >
                                <option value=""> -- Select State -- </option>
                                {State
                                  ? [
                                      State.map((S) => (
                                        <option
                                          key={`state_${S.State.state_name}`}
                                          value={S.State.state_name}
                                        >
                                          {S.State.state_name}
                                        </option>
                                      )),
                                    ]
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a state.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Pincode</b>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                id="txt_zipcode_new_billing"
                                name="txt_zipcode_new_billing"
                                value={zipcode_billing}
                                onChange={handleChangeFieldbilling}
                                maxLength={6} // Set the maximum length to 6 digits
                                onKeyDown={(e) => {
                                  if (
                                    e.target.value.length >= 6 &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault(); // Prevent further input after 6 digits
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Btn
                        attrBtn={{ color: "secondary", onClick: togglebilling }}
                      >
                        Close
                      </Btn>
                      <Button color="primary" type="submit">
                        SaveChanges
                      </Button>
                      {/* <Btn attrBtn={{ color: "primary",onclick: handleSubmitAddressBilling }}>
                  SaveChanges
                </Btn> */}
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Shipping address  */}
                <Modal
                  isOpen={shippingmodal}
                  toggle={toggleshipping}
                  size="lg"
                  backdrop="static"
                  keyboard={false}
                >
                  <ModalHeader toggle={toggleshipping}>
                    Shipping Address
                  </ModalHeader>
                  <Form onSubmit={(e) => handleSubmitAddressShipping(e)}>
                    <ModalBody className="grid-showcase">
                      <Container fluid={true} className="bd-example-row">
                        <Row className="mb-3">
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Type</b>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                id="txt_add_type_new_shipping"
                                name="txt_add_type_new_shipping"
                                value={nickname_shipping}
                                onChange={handleChangeFieldShipping}
                                placeholder="Address"
                                required
                                rows={2}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Address Line 1</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_add_new1_shipping"
                                name="txt_add_new1_shipping"
                                value={address1_shipping}
                                onChange={handleChangeFieldShipping}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Addrss Line 2</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_add_new2_shipping"
                                name="txt_add_new2_shipping"
                                value={address2_shipping}
                                onChange={handleChangeFieldShipping}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Area</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_area_new_shipping"
                                name="txt_area_new_shipping"
                                value={area_shipping}
                                onChange={handleChangeFieldShipping}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>City</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="txt_city_new_shipping"
                                name="txt_city_new_shipping"
                                value={city_shipping}
                                onChange={handleChangeFieldShipping}
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={6}>
                            <Form.Group controlId="sel_country">
                              <Form.Label>
                                <b>Country</b>
                              </Form.Label>
                              <Form.Select
                                id="sel_country_shipping"
                                name="sel_country_shipping"
                                onChange={(e) => {
                                  changestate(e, 0);
                                }}
                                value={selcountry_shipping}
                                required
                              >
                                <option value=""> -- Select Country -- </option>
                                {country
                                  ? [
                                      country.map((C) => (
                                        <option
                                          key={`country_${C.Country.country_code}`}
                                          value={C.Country.country_name}
                                        >
                                          {C.Country.country_name}
                                        </option>
                                      )),
                                    ]
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a country.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={6} md={6}>
                            <Form.Group controlId="sel_state_new">
                              <Form.Label>
                                <b>State</b>
                              </Form.Label>
                              <Form.Select
                                id="sel_state_new_shipping"
                                name="sel_state_new_shipping"
                                value={selstate_shipping}
                                onChange={handleChangeFieldShipping}
                                required
                              >
                                <option value=""> -- Select State -- </option>
                                {State
                                  ? [
                                      State.map((S) => (
                                        <option
                                          key={`state_${S.State.state_name}`}
                                          value={S.State.state_name}
                                        >
                                          {S.State.state_name}
                                        </option>
                                      )),
                                    ]
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select a state.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} md={6}>
                            <Form.Group>
                              <Form.Label>
                                <b>Pincode</b>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                id="txt_zipcode_new_shipping"
                                name="txt_zipcode_new_shipping"
                                value={zipcode_shipping}
                                onChange={handleChangeFieldShipping}
                                maxLength={6} // Set the maximum length to 6 digits
                                onKeyDown={(e) => {
                                  if (
                                    e.target.value.length >= 6 &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault(); // Prevent further input after 6 digits
                                  }
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Btn
                        attrBtn={{
                          color: "secondary",
                          onClick: toggleshipping,
                        }}
                      >
                        Close
                      </Btn>
                      <Button color="primary" type="submit">
                        SaveChanges
                      </Button>
                      {/* <Btn
                      attrBtn={{ color: "primary", onClick: toggleshipping }}
                    >
                      SaveChanges
                    </Btn> */}
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* modal attribute */}
                <Modal
                  isOpen={showattrmodal}
                  toggle={toggleattributemodal}
                  size="xl"
                  backdrop="static"
                  keyboard={false}
                >
                  <ModalHeader
                    toggle={() => {
                      setShowattrmodal(!showattrmodal);
                      setProductselectid("");
                      setSelectedProduct(null); // Reset selected item
                    }}
                    className="Modal-addressheader"
                  >
                    Add Attributes for {AttrProductname}
                    {productImage && (
                      <img
                        src={productImage}
                        alt={AttrProductname}
                        className="img-fluid ml-2 ms-2"
                        style={{
                          maxWidth: "50px",
                          maxHeight: "50px",
                          borderRadius: "10px",
                        }}
                      />
                    )}
                  </ModalHeader>
                  <Form onSubmit={handleSubmit(onsubmit)}>
                    <ModalBody>
                      <Row>
                        {pricelist_array.length > 0 ? (
                          <div>
                            <Table striped bordered hover responsive="xl">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Price</th>
                                  {Object.keys(attr_label).length > 0 &&
                                    Object.keys(attr_label).map((key) => (
                                      <th key={key}>
                                        {attr_label[key]?.Label}
                                      </th>
                                    ))}
                                </tr>
                              </thead>
                              <tbody>
                                {pricelist_array.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() => Saveattributejson(index)}
                                    >
                                      <IoIosAddCircle size={25} />
                                    </td>
                                    <td>{item.price}</td>
                                    {item.attr_arr.map((attr, attrIndex) => (
                                      <td
                                        key={attrIndex}
                                        className="text-center"
                                      >
                                        {attr.Value ? attr.Value : "---"}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div>No attribute found</div>
                        )}
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setShowattrmodal(!showattrmodal);
                          setProductselectid("");
                          setSelectedProduct(null); // Reset selected item
                          setJson({});
                          setJsonName({});
                        }}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboardpos;
